import React from "react"
import { ModularPage } from "../components"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => <ModularPage data={data} />

export const query = graphql`
  query HomeQuery {
    datoCmsPage(slug: { eq: "home" }) {
      slug
      title
      ...SeoMetaTags
      ...HeaderImage
      ...Modules
    }
  }
`

export default IndexPage
